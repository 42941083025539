import { useState, useEffect } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { Box, CircularProgress } from "@mui/material";

export default function UnityGame() {
  const [windowSize, setWindowSize] = useState({
    width: 960,
    height: 540,
    devicePixelRatio: window.devicePixelRatio,
  });

  const { unityProvider, sendMessage, isLoaded, loadingProgression } =
    useUnityContext({
      loaderUrl: "build/WebGL.loader.js",
      dataUrl: "build/WebGL.data",
      frameworkUrl: "build/WebGL.framework.js",
      codeUrl: "build/WebGL.wasm",
    });

  useEffect(() => {
    if (isLoaded) {
      const script = document.createElement("script");
      script.src = "build/WebGL.loader.js";
      script.id = "unity_instance";
      //@ts-expect-error
      script.sendMessage = sendMessage;
      document.body.appendChild(script);
      console.log("appended script, ", script);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  // Resize unity game when window size changes
  useEffect(() => {
    const handleResize = () => {
      var maxHeight = window.innerHeight - 25;
      var maxWidth = window.innerWidth - 25;
      if (window.innerWidth > 1200) maxWidth -= 25;

      let width, height;

      width = Math.min(maxWidth, maxHeight * (960 / 540));
      height = Math.min(maxHeight, maxWidth * (540 / 960));

      setWindowSize({
        width,
        height,
        devicePixelRatio: window.devicePixelRatio,
      });
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: "#090808",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          // height: `calc(100vh - 64px)`,
          width: "100%",
        }}
      >
        <Box>
          {isLoaded === false && (
            <Box
              sx={{
                position: "absolute",
                zIndex: 100,
                width: windowSize.width,
                height: windowSize.height,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                fontWeight: "bold",
                fontSize: "25px",
                color: "white",
              }}
            >
              <CircularProgress
                size={75}
                sx={{
                  color: "white",
                  width: "200px",
                  height: "200px",
                }}
              />
              <br />
              {Math.round(loadingProgression * 100)}%
            </Box>
          )}
          <Unity
            unityProvider={unityProvider}
            devicePixelRatio={windowSize.devicePixelRatio}
            style={{
              width: windowSize.width,
              height: windowSize.height,
              borderRadius: "5px",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

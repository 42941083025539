import "./App.css";
import UnityGame from "./UnityGame";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const handler = (event: any) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", handler);
    return () => {
      window.removeEventListener("beforeunload", handler);
    };
  }, []);

  return (
    <div className="App">
      <UnityGame />
    </div>
  );
}

export default App;
